import { Spinner } from '@netfront/ui-library';
import cx from 'classnames';
import Head from 'next/head';

import { PrivateFooter, PublicFooter } from '../Footer';
import { PrivateHeader, PublicHeader } from '../Header';

import { PAGE_LAYOUT_CONSTANTS } from './PageLayout.constants';
import { IMeta, PageLayoutProps } from './PageLayout.interfaces';

/**
 * @todo Add meta properties
 */

const PageLayout = ({
  bodyClassName,
  children,
  className,
  hasPrivateLayout = false,
  isPreloaderVisible = false,
  meta,
  title,
}: React.PropsWithChildren<PageLayoutProps>) => {
  const { description = 'Description' } = meta ?? ({} as IMeta);

  const { titlePrefix } = PAGE_LAYOUT_CONSTANTS;
  const pageTitle = title ? `${titlePrefix} | ${title}` : titlePrefix;

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        <meta content={description} name="description" />
      </Head>
      <div className={cx('flex flex-col min-height-screen', bodyClassName)}>
        {hasPrivateLayout ? <PrivateHeader /> : <PublicHeader />}
        <Spinner isLoading={isPreloaderVisible} />
        <main className={cx('flex-1', 'relative', className)}>{children}</main>
        {hasPrivateLayout ? <PrivateFooter /> : <PublicFooter />}
      </div>
    </>
  );
};

export { PageLayout };
