import { useAuthentication } from '@netfront/gelada-identity-library';
import cx from 'classnames';

import { IPreHeader } from './PreHeader.interfaces';

import { Link } from '../../Link';

const PreHeader = ({ isDisplay }: IPreHeader) => {
  const { isAuthenticated } = useAuthentication();

  return (
    <div
      className={cx('bg-accent xl:block', {
        none: !isDisplay,
      })}
    >
      <div className="container-xl">
        <div className="align-center flex justify-end -mx-4">
          {isAuthenticated ? (
            <Link className="color-white font-header hover:bg-green-500 p-4 weight-500" href="/dashboard">
              Go to program dashboard
            </Link>
          ) : (
            <>
              <Link className="color-white font-header hover:bg-green-500 p-4 weight-500" href="/register">
                Register
              </Link>
              <Link className="color-white font-header hover:bg-green-500 p-4 weight-500" href="/login">
                Login
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export { PreHeader };
