import { useRef } from 'react';

import { useLink } from '@react-aria/link';
import cx from 'classnames';
import NextLink from 'next/link';

import { BUTTON_CLASSES } from '../Button';

import { LinkProps } from './Link.interfaces';

const Link = ({ as, children, className = '', href, isDisabled, target, theme, ...rest }: LinkProps) => {
  const linkRef = useRef(null);

  const { linkProps } = useLink(
    {
      isDisabled,
    },
    linkRef,
  );

  const linkTheme = theme ? BUTTON_CLASSES[theme] : '';

  return (
    <NextLink as={as} href={href}>
      <a
        {...linkProps}
        ref={linkRef}
        className={cx({
          'events-none': isDisabled,
          cursor: !isDisabled,
          [className]: className,
          [linkTheme]: linkTheme,
        })}
        target={target}
        {...rest}
      >
        {children}
      </a>
    </NextLink>
  );
};

export { Link };
